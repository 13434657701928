import PopHomeView from "../MainView/MainHomeView";
import React from "react";
import { client } from "../../client";
import { useQuery } from "@tanstack/react-query";

import { cleanUpData } from "./clean-data";


const fetchVideos = async () => {
  const response = await client.getEntries({ content_type: "adVideo" });
  return cleanUpData(response.items);
};
function AppView() {
  const {
    data: videos,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["videos"],
    queryFn: fetchVideos,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
  });

  const memoizedData = React.useMemo(() => videos || [], [videos]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading videos: {error.message}</div>;
  }

  return (
    <div>
      <PopHomeView data={memoizedData} />
    </div>
  );
}

export default AppView;
