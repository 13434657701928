

export const cleanUpData = (rawData) => {
    return rawData.map((videodata) => {
      const { sys, fields } = videodata;
      const { id } = sys;
      return {
        id,
        videoName: fields.videoName,
        videoDirectorName: fields.directorName,
        videoCoverImage: fields.videoCoverImage.fields.file.url,
        videoForGif: fields.videoForGif.fields.file.url,
        linkForVimeo: fields.linkForVimeo,
        cssid: fields.cssid,
        producer: fields.producer,
        videoType: fields.videoType,
        secuence: fields.secuence,
        Gaffer: fields.gaffer,
        PostProduction: fields.postProduction,
        CC: fields.cc,
        SD: fields.sd,
        Agency: fields.agency,
        Client: fields.client,
        Year: fields.year,
        Production: fields.production,
        MusicSound: fields.musicSound,
        VO: fields.vo,
        ArtDepartment: fields.artDepartment,
        DOP: fields.dop,
        setDesign: fields.setDesign,
      };
    });
  };