import styled from "styled-components";
// import ViewersInfo from "./Viewer/ViewerInfo";
import React from "react";

const LazyInfo = React.lazy(() => import("./Viewer/ViewerInfo"));

const Info = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<div>Loading...</div>}>
        <img
          src="/images/kadruskyriusInfoFoto.jpg"
          alt="kadruskyriusInfoFoto"
        />
        <LazyInfo />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  display: block;
  padding: 0 calc(13.5vw);
  margin-top: 13vh;
  /* top:100px; */
  align-items: center;
  img {
    width: 100%;
    /* max-height: 10%; */
    object-fit: fill;
  }
  @media (max-width: 768px) {
    margin-top: 15vh;
    padding: 8px;
  }
`;

export default Info;
