import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./Home.js";
import Films from "./Films";
import Ads from "./Ads";
import Info from "./Info";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/films" element={<Films />} />
        <Route path="/ads" element={<Ads />} />
        <Route path="/info" element={<Info />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
