import React, { useEffect } from "react";
import {
  CloseBtn,
  Overlay,
  ModalContainer,
  Text,
} from "../Styles/PopUp.Styled.js";
import Vimeo from "@u-wave/react-vimeo";
import { motion } from "framer-motion";

function HomeView(props) {
  useEffect(() => {
    function escFunction(event) {
      if (event.key === "Escape") {
        props.onHide();
      }
    }

    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [props]);

  return (
    <React.Fragment>
      {props.show && (
        <Overlay
          as={motion.div}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
        >
          <CloseBtn onClick={props.onHide} onTouchStart={props.onHide}>
            &times;
          </CloseBtn>
          <ModalContainer>
            <Vimeo
              video={props.vimeo}
              autoplay={false}
              responsive={true}
              showPortrait={false}
              showTitle={false}
            />
            <Text>
              {props.text}
              <div>
                <p>{props.director}</p>
                <p>{props.dop}</p>
                <p>{props.artdp}</p>
                <p>{props.ms}</p>
                <p>{props.vo}</p>
                <p>{props.setDesigner}</p>
                <p>{props.gaffer}</p>
                <p>{props.postProduction}</p>
                <p>{props.cc}</p>
                <p>{props.sd}</p>
                <p>{props.producer}</p>
                <p>{props.agency}</p>
                <p>{props.client}</p>
                <p>{props.production}</p>
                <p>{props.year}</p>
                <p>{props.addrow1}</p>
                <p>{props.addrow2}</p>
                <p>{props.addrow3}</p>
              </div>
            </Text>
          </ModalContainer>
        </Overlay>
      )}
    </React.Fragment>
  );
}

export default HomeView;
