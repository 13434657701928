import styled from "styled-components";
// import AppViewFilms from "./Viewer/ViewFilms.jsx";
import React from "react";

const LazyFilms = React.lazy(() => import("./Viewer/ViewFilms.jsx"));

const Films = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyFilms />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  display: block;
  margin-top: 73px;
  padding: 0 calc(3.5vw + 5px);
  @media screen and (max-width: 768px) {
    padding: 0
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  }
`;

export default Films;
